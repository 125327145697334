import React from "react"
import { graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import Loadable from "@loadable/component"
import Img from "gatsby-image"
import ReactMapGL from 'react-map-gl'
import {Helmet} from 'react-helmet'

import Demo from "../components/Demo"
import DemoTable from "../components/DemoTable"
import Layout from "../components/layouts/Layout"
import FeatureTile from "../components/FeatureTile"
import FeatureBlock from "../components/FeatureBlock"
import DemoStyles from "../components/Demo.module.scss"
import Button from "../components/Button"

const variants = {
  hidden: { opacity: 0.2 },
  visible: { opacity: 1 },
}

const header = () => (
  <div className={`bg-gray-100`}>
    <div className="w-full max-w-screen-xl mx-auto px-6 pb-8">
      <div className="flex flex-wrap flex-row">
        <div className="w-full flex">
          <motion.div 
            className="pt-6 text-left"
            initial="hidden"
            animate="visible"
            variants={variants}
            >
            <h1 className="text-4xl text-black" style={{maxWidth: 420+'px', lineHeight: 60+'px', marginBottom: 0}}>What is a Verified Seller?</h1>
          </motion.div>
        </div>
      </div>
      
    </div>
  </div>
)

export default ({}) => {
  return (
    <Layout header={header()}>
      <Helmet>
        <title>What is a Verified Seller?</title>
        <meta name="description" content="Verified Sellers have passed multiple manual authentication and verification processes performed by an independent third party assessor." />
        <meta property="og:description" content="Verified Sellers have passed multiple manual authentication and verification processes performed by an independent third party assessor." />
      </Helmet>
      <p className="mt-2"></p>
      <div className={`flex sm:flex-row flex-wrap my-8`}>
            <div className="flex items-center">
                <div className={`pt-2`}>
                    <div className="text-lg pb-4">
                    Verified Sellers have passed multiple manual authentication and verification processes performed by an independent third party assessor. (CounterSet) <br />
                    </div>

                    <div className="text-lg">
                      These processes include:
                      <ul className="mt-4 ml-2 tick-list">
                        <li>Business license check</li>
                        <li>D-U-N-S verification</li>
                        <li>Contact and domain ownership</li>
                        <li>On-site check</li>
                        <li>Product verification</li>
                        <li>Trademark verification</li>
                      </ul>
                    </div>
                </div>
            </div>
      </div>

      <div className={`flex sm:flex-row flex-wrap my-12`}>
            <div className="flex items-center">
                <div className={`pt-2`}>
                    <h3 className="font-bold text-2xl mb-2">Contact Us</h3>
                    <div className="text-lg pb-4">
                    For further information please contact us at: <br/> verified@counterset.com
                    </div>
                </div>
            </div>
        </div>
  </Layout>
  )
}
